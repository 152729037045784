<template>
  <div class="form-check d-flex" :class="alignment">
    <input
      :id="id"
      class="form-check-input "
      type="checkbox"
      :name="name"
      :checked="checked"
      @input="$emit('projectId', $event.target.checked)"
      :class="borderColor"
    />
    <label :for="id" class="custom-control-label" :class="$attrs.class">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "SoftCheckbox",
  emit: ['projectId'],
  props: {
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: "",
    },
    alignment: {
      type: String,
      default: "",
    }
  },
};
</script>
