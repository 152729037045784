<template>
  <div class="py-4 container-fluid ">
    <div class="mt-3 m-4">
      <div class="mb-4 card p-3">
        <FullCalendar :key="calendarKey" :options='calendarOptions'/>
      </div>
    </div>
  </div>
</template>



<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import api, {apiGet} from "@/api/api";
import {nextTick} from "vue";
import setNavPills from "@/assets/js/nav-pills";
import setTooltip from "@/assets/js/tooltip";

export default {
  components: {
    FullCalendar
  },
  data: function () {
    return {
      loadingData: false,
      calendarKey: 0,
      postList: [],
      calendarOptions: {
        eventClick: (info) => {
          this.editPost(info)
        },
        locale: 'de',
        plugins: [timeGridPlugin, dayGridPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'dayGridMonth,timeGridWeek,timeGridDay',
          center: 'title',
        },
        weekends: true,
        firstDay: 1,
        buttonText: {
          today: 'Heute',
          month: 'Monat',
          week: 'Woche',
          day: 'Tag',
        },
        allDayText: 'Jeden Tag',
        views: {
          month: {
            titleFormat: {year: 'numeric', month: 'long'}
          },
          week: {
            titleFormat: {year: 'numeric', month: 'long', day: 'numeric'}
          }
        },
        events: [],
        eventDisplay: 'block'

      }
    }
  },

  computed: {
    activeId() {
      return parseInt(this.$store.state.activeId);
    },
  },

  methods: {
    editPost(info) {
      let id = info.event._def.publicId;
      this.$router.push('/single-post?postId=' + id + '&c=' + this.activeId)
    },
    async setCompany() {
      let resp;
      this.loadingData = true;
      if (this.$store.state.activeId === undefined || this.$store.state.activeId === null) {
        resp = await apiGet(localStorage.getItem('adminId'),'/post?companyId=' + localStorage.getItem('companyId'))
      } else {
        resp = await apiGet(localStorage.getItem('adminId'),'/post?companyId=' + this.activeId)
      }

      this.postList = resp.data.posts.map((x) => {
        return {
          ...x,
          text: decodeURI(x.text),
          publicationDate: decodeURI(x.publicationDate) || new Date(),
        }
      })

      this.postList = this.postList.reverse()
      this.loadingData = false;

      await this.setEvents();
    },

    async setEvents() {
      this.calendarOptions.events = [];

      for (let i = 0; i <= this.postList.length; i++) {
        const item = this.postList[i];
        if (this.postList[i]?.publicationDate) {
          this.calendarOptions.events[i] = {
            id: item.id,
            title: this.postList[i].text,
            date: this.postList[i].publicationDate,
          }
        }
      }
    },
  },

  mounted() {
    this.$store.state.showNavbarMini = false;
    this.$store.state.isAbsolute = true;
    const sidenav_show = document.querySelector(".g-sidenav-show");
    sidenav_show.classList.remove("g-sidenav-hidden");
    sidenav_show.classList.add("g-sidenav-pinned");
    sidenav_show.children[0].style.setProperty("background-color", "white", "important");

    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    this.setCompany()
  },
};

</script>