import axios from 'axios'

const AUTH_TOKEN = localStorage.getItem('AUTH_TOKEN')
const TYP_TOKEN = localStorage.getItem('tokenType')
const COMPANY_ID = localStorage.getItem('companyId')

export const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'companyId': COMPANY_ID ? COMPANY_ID : ''
  },
})
if (AUTH_TOKEN && TYP_TOKEN) {
  setToken(AUTH_TOKEN, TYP_TOKEN)
}

export function setToken(AUTH_TOKEN, TYP_TOKEN) {
  api.defaults.headers.common['Authorization'] = TYP_TOKEN + ' ' + AUTH_TOKEN
}

export async function apiGet(ID, path) {
  let data;
  await api.get(`${path}`, {
    headers: {
      'companyId': ID,
    }
  })
      .then(response => {
        data = response
      })
  return data
}

export default api

