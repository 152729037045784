<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div
              class="px-5 col-xl-5 col-lg-6 col-md-8 col-12 d-flex flex-column"
            >
              <div class="mt-8 card card-plain">
                <div class="pb-0 text-left card-header">
                  <h3 class="text-primary ">Kontakt</h3>
                  <p class="mb-3">
                    Lüttel Software & Medien GmbH<br>
                    Eichholzstr. 9<br>
                    58540 Meinerzhagen<br>
                  </p>
                  E-Mail:<a href="mailto:info@software-medien.de" class="text-decoration-underline"> info@software-medien.de</a><br/>
                  Telefon:<a href="tel:+4923547795154" class="text-decoration-underline"> 02354 / 7795154</a><br/>
                </div>
                <div class="pb-3 card-body">
                    <div class="text-center">
                      <router-link to="/login">
                        <soft-button
                            id="backBtn"
                            color="secondary"
                            variant="outline"
                            full-width
                            border-radius="25px"
                            class="mt-2 ">
                          Zurück
                        </soft-button>
                      </router-link>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/contact.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import AppFooter from "./Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
import EasyNavbar from "@/views/easy-social/EasyNavbar.vue";
export default {
  name: "EasySocialLoginResetPassword",
  components: {
    EasyNavbar,
    AppFooter,
    SoftInput,
    SoftButton,
  },
  created() {
    this.$store.state.showNavs = false;
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>
