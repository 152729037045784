<template>
  <main class="p-4 d-flex flex-column align-items-center d-grid ">
    <div v-if="onlyItemsAUserCanHandle.length > 0" v-for="(item, idx) in onlyItemsAUserCanHandle" :key="idx">
      <review-plattforms
          :item="item"
      />
    </div>
    <div v-else class="mt-5">
      <img src="../../assets/img/doneReview.svg" alt="empty state" style="width: 100vh; max-width: 100%"/>
      <p class="mt-5 col-12 text-center">Keine Beiträge zur Freigabe verfügbar.</p>
    </div>
    <app-footer class="" style=""/>
  </main>
</template>

<script>
import AppFooter from "./Footer.vue"
import {mapMutations} from "vuex";
import api from "@/api/api";
import moment from "moment";
import ReviewPlattforms from "@/components/ReviewPlattforms.vue";
import VueCropper from "@/components/VueCropper.vue";

export default {
  name: "ReviewPage",
  components: {
    VueCropper,
    ReviewPlattforms,
    AppFooter,
  },
  data() {
    return {
      companies: [],
      reviewPosts: [],
    }
  },
  computed: {
    onlyItemsAUserCanHandle() {
      return this.reviewPosts.filter((y) => {
        if(decodeURI(y.status) === "IN_EXAMINATION") {
          return y
        }
      })
    },
    activeId() {
      return parseInt(this.$store.state.activeId);
    },
  },

  created() {
    this.hideEveryDisplay();
  },

  async mounted() {
    await this.setCompany();
    await this.sortPosts();

  },
  methods: {
    ...mapMutations(["hideEveryDisplay"]),
    async setCompany() {
      let resp;
      this.loadingData = true;

      if (JSON.parse(localStorage.getItem('companies')) === '') {
        await api.get('/company').then((resp) => {
          localStorage.setItem('companies', JSON.stringify(resp.data))
          this.companies = resp.data
        }).catch((e) => {
          console.error(e)
          this.$router.push({name: 'review'})
        })
      } else {
        this.companies = JSON.parse(localStorage.getItem('companies'));
      }
      let companyId = this.companies[0].id;

      this.$store.dispatch('setActiveCompany', companyId);
      localStorage.setItem('companyId', companyId)


      if (this.$store.state.activeId !== undefined || this.$store.state.activeId === null) {
        resp = await api.get('/post/review?companyId=' + companyId)
      } else {
        resp = await api.get('/post/review?companyId=' + this.activeId)
      }

      this.reviewPosts = resp.data.posts.map((x) => {
        return {
          ...x,
          instagramText: decodeURI(x.instagramText),
          facebookText: decodeURI(x.facebookText),
          linkedinText: decodeURI(x.linkedinText),
          publicationDate: x.publicationDate,
          formatedDate: moment(x.publicationDate).format('DD.MM.yyyy')
        }
      })

      this.company = resp.data.company
      this.loadingData = false;
    },

    sortPosts() {
      this.reviewPosts.sort(function (a, b) {
        let dateA = new Date(a.publicationDate);
        let dateB = new Date(b.publicationDate);
        return dateA - dateB;
      });
    },

  },
};


</script>
