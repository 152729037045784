<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-6 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-primary" id="welcomeText">
                    Willkommen zurück auf Easy Social
                  </h3>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="login">
                    <label id="userLabel">Benutzername</label>
                    <soft-input
                        id="name"
                        v-model="name"
                        type="name"
                        placeholder="Name"
                        name="name"
                        :border-color="loginFail ? 'border-danger' : ''"
                    />
                    <label id="passwordLabel">Passwort</label>
                    <soft-input
                        id="password"
                        v-model="password"
                        type="password"
                        placeholder="Passwort"
                        name="password"
                        :border-color="loginFail ? 'border-danger' : ''"
                    />
                    <p v-if="loginFail" class="text-danger" id="alertText">Der Benutzername oder das Passwort ist
                      falsch</p>
                    <div class="text-center">
                      <soft-button
                          class="my-4 mb-2"
                          color="dark"
                          full-width
                          type="submit"
                          id="loginBtn"
                      >Einloggen
                      </soft-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    <router-link
                        to="/contact"
                        id="youNeedHelpBtn"
                        class="text-dark font-weight-bold">
                      Brauchst Du hilfe?
                    </router-link
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                  class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                    class="bg-cover border-primary oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6 login-background-image"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer/>
</template>

<script>
import AppFooter from "./Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
import {mapMutations} from "vuex";
import api, {apiGet} from "@/api/api";
import SoftSnackbar from "@/components/SoftSnackbar.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "EasySocialLogin",
  data() {
    return {
      name: '',
      password: '',
      loginFail: false,
    }
  },
  components: {
    SoftSnackbar,
    AppFooter,
    SoftInput,
    SoftSwitch,
    SoftButton,
  },

  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    localStorage.clear();
    this.$store.commit('resetState');

    delete api.defaults.headers.common["Authorization"];
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    async login() {
      this.loginFail = false

      await api.post('/auth/login', {
        username: this.name,
        password: this.password,
      })
          .then(async (r) => {
            localStorage.setItem('AUTH_TOKEN', r.data.accessToken)
            localStorage.setItem('tokenType', r.data.type)
            api.defaults.headers.common['Authorization'] = 'Bearer ' + r.data.accessToken
            let userRole = r.data.smallUser.roles[0];
            localStorage.setItem('userRole', userRole)
            localStorage.setItem('companyId', r.data.smallUser.companies[0])
            if (userRole === 'medien')
            {
              localStorage.setItem('adminId', r.data.smallUser.ident)
            }

            let all = ''
            userRole === 'medien' ? all = '/all' : all = ''

            if (r.data.accessToken !== undefined) {
              await apiGet(localStorage.getItem('companyId'), '/company' + all).then(async (resp) => {
                localStorage.setItem('companies', JSON.stringify(resp.data))

                if (userRole === "admin" || userRole === "medien") {
                  await this.$router.push({name: 'LandingPage'})
                } else {
                  await this.$router.push({name: 'ReviewPage'})
                }
              }).catch((e) => {
                this.loginFail = true
              })
            }
          })
          .catch((e) => {
            this.loginFail = true
          })
    },
  },

};


</script>
