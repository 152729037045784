<template>
  <div>
    <cropper
        ref="cropper"
        class="cropper"
        :stencil-props="{
          aspectRatio: imgAspectRation === '9/16'? 9/16 : 1/1,
        }"
        :src="propImg"
        @change="change"
    />
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import Navigation from "./VueCropperNavigation.vue";
import {ref} from "vue";

export default {
  name: "VueCropper",
  components: {
    Cropper,
    Navigation,
  },
  data: () => ({
    coordinates: ref(''),
    canvas: ref(''),
    cropImg: "",
  }),
  props: {
    imageCropListData: {
      type: Array
    },
    index: {
      type: Number
    },
    propImg: {
      type: String,
      default: ""
    },
    identifier: {
      type: Number,
      default: "",
      require: true
    },
    imgAspectRation: {
      type: String,
      default: '9/16',
      validator(value, props) {
        // The value must match one of these strings
        return ['9/16', '1/1',].includes(value)
      }
    }
  },
  methods: {
    cropImage() {
      const result = this.$refs.cropper.getResult();
      this.imageCropListData[this.index] = {
        ...this.imageCropListData[this.index],
        croppedImg: result.canvas.toDataURL("image/jpg")
      }
    },
    change({coordinates, canvas}) {
      this.coordinates = coordinates;
      this.canvas = canvas;
      this.cropImage()
    },
  },
};
</script>

<style lang="scss">
.twitter-cropper {
  height: 300px;
  &__background {
    background-color: #edf2f4;
  }
  &__foreground {
    background-color: #edf2f4;
  }
  &__stencil {
    border: solid 5px rgb(29, 161, 242);
  }
}
</style>
