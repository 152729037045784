<template>
  <div
      id="sidenav-collapse-main"
      class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item"
          v-for="(company, idx) in companies?.filter((x) => x.id === parseInt(activeId))"
          :key="idx">

        <sidenav-collapse
            :nav-text="company.name"
            class="active"
        >
          <template #logo>


            <img v-if="company.base64Image !== null" :src="company.base64Image"
                 class="avatar avatar-sm"
                 :alt="company.name"
            >
            <div v-else>
              <i class="fa fa-solid fa-user"></i>
            </div>

          </template>
          <template #list>
            <ul class="ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                  :to="{ name: 'PostGallery'}"
                  text="Gallery"
                  mini-icon="G"
                  @click="setActiveCompany(company.id)"
              />
              <sidenav-item
                  :to="{ name: 'Uploader'}"
                  mini-icon="U"
                  text="Hochladen"
                  @click="setActiveCompany(company.id)"
              />
              <sidenav-item
                  :to="{ name: 'Kanban'}"
                  mini-icon="K"
                  text="Kanban"
                  @click="setActiveCompany(company.id)"
              />
              <sidenav-item
                  :to="{ name: 'Calendar'}"
                  mini-icon="U"
                  text="Kalender"
                  @click="setActiveCompany(company.id)"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>

  <div class="d-flex align-items-center justify-content-center w-100 position-absolute bottom-0">
    <router-link
        v-if="this.$route.name !== 'LandingPage'"
        :to="{ name: 'LandingPage'}"
    >
      <soft-button
          class="my-4 mb-4"
          color="primary"
          full-width
          type="submit"
          id="loginBtn"
          @click="resetCompany"
      >
        Unternehmen wechseln
      </soft-button>
    </router-link>
  </div>
</template>
<script>
import {mapState} from "vuex";
import api from "@/api/api";
import SoftButton from "@/components/SoftButton.vue";
import SidenavCollapse from "@/examples/Sidenav/SidenavCollapse.vue";
import SidenavItem from "@/examples/Sidenav/SidenavItem.vue";

export default {
  name: "SidenavList",
  components: {
    SoftButton,
    SidenavItem,
    SidenavCollapse,
  },

  props: {
    cardBg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      companies: [],
    }
  },
  computed: {
    ...mapState(["isRTL"]),

    activeId() {
      return this.$store.state.activeId;
    },

    activeCompanyId() {
      return this.$store.state.activeCompanyId
    },
  },
  async mounted() {
    if (JSON.parse(localStorage.getItem('companies')) === '') {
      await api.get('/company').then((resp) => {
        localStorage.setItem('companies', JSON.stringify(resp.data))
        this.companies = resp.data
      }).catch((e) => {
        console.error(e)
        this.$router.push({name: 'Login'})
      })
    } else {
      this.companies = JSON.parse(localStorage.getItem('companies'))
    }
    if (this.$store.state.activeCompanyId === undefined || this.$store.state.activeCompanyId === null) {
      this.$store.state.activeCompanyId = localStorage.getItem('companyId')
    }
  },


  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    getActiveCompany(company) {
      return this.$store.state.activeCompanyId === company.id.toString();
    },
    setActiveCompany(id) {
      this.$store.state.activeCompanyId = id.toString();
      localStorage.setItem('companyId', id.toString())

      this.activeCompanyId = id;
    },
    resetCompany() {
      this.$store.dispatch('deactivateCompany');
      localStorage.setItem('companyId', localStorage.getItem('adminId'))
    }
  },
};
</script>
