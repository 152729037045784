<template xmlns="http://www.w3.org/1999/html">
  <div class="py-4 container-fluid">
    <div class="row px-2">
      <div class="col-lg-12">
        <a @click="goBackToGallery" class="py-3 mb-5">
          <button
              type="button"
              class="btn bg-light"
          >
            <i class="fa fa-arrow-left pe-2"></i>
            Bilder Galerie
          </button>
        </a>
        <div
            class="col-lg-4 d-flex d-md-none align-items-center justify-content-end gap-3 pb-5"
        >
          <button
              @click="deletePost"
              type="button"
              class="mt-2 mb-0 btn bg-dark "
          >
            Löschen
          </button>
          <button
              @click="savePost"
              type="button"
              class="mt-2 mb-0 btn bg-primary"
          >
            Speichern
          </button>
        </div>

        <div class="d-flex row">
          <div class="col-md-3 col-sm">
            <p>
              Beschreibe mit Text und Hashtags den Inhalt deines Bildes
            </p>
          </div>
          <div class="col-md-2 col-sm"><label>Vollständigkeit</label>
            <p :class="`mb-2 text-sm fw-bold text-${statusConverterColor(post)}`">{{ statusConverterName(post) }}</p>
          </div>
          <div class="col-md-3 col-sm">
            <label class="form-label">Status</label>
            <select
                id="image-status"
                class="form-control"
                name="change Status"
                v-model="post.status">
              >
            </select>
          </div>
          <div class="col-md-4 col-lg col-md d-none d-md-flex align-items-center justify-content-end gap-3">
            <button
                @click="deletePost"
                type="button"
                class="mt-2 mb-0 btn bg-dark"
            >
              Löschen
            </button>
            <button
                @click="savePost"
                type="button"
                class="mt-2 mb-0 btn bg-primary"
            >
              Speichern
            </button>
          </div>
        </div>

      </div>
      <div v-if="post?.declinedReason" class="card mt-3 pt-3 bg-warning">
        <p>
          <b>Ablehnungsgrund: </b>
        {{ post.declinedReason }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-auto col-md-auto card pt-3 pb-3 my-3" v-for="(img, idx) in post.images" :key="idx">
        <img
            class="border-radius-lg"
            style="max-height: 50rem; object-fit: contain;"
            :src="img?.base64Image"
            alt="product_image"
        />
      </div>
      <div class="col my-3">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Bild Informationen</h5>
            <div class="row">
              <div class="col-12 mb-1">
                <div class="mb-3">
                  <label for="textFormInput" class="form-label">Text</label>
                  <textarea v-model="post.text" class="form-control" id="textFormInput" rows="5"
                            maxlength="5000"></textarea>
                </div>
              </div>
              <div class="col-12 mb-3">
                <div class="mb-3">
                  <label for="hashtagFormInput" class="form-label">Hashtags</label>
                  <textarea v-model="post.hashtags" class="form-control" id="hashtagFormInput" rows="2"
                            maxlength="3000"></textarea>
                </div>
                <div class="row">
                  <div class="col-6 mb-3">
                    <label>Zeitpunkt</label>
                    <soft-input
                        id="zeitpunkt"
                        v-model="post.time"
                        dataformatas="24hr"
                        filled
                        type="time"
                    />
                  </div>
                  <div class="col-6 mb-3">
                    <label>Datum</label>
                    <soft-input
                        id="datum"
                        v-model="post.date"
                        filled
                        type="date"
                    />
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <div class="mb-3">
                    <label for="additionalInfoTextFormInput" class="form-label">Zusatzinformationen (z.B.
                      Zeitplan)</label>
                    <textarea v-model="post.additionalInfoText" class="form-control" id="additionalInfoTextFormInput"
                              rows="2" maxlength="3000"></textarea>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Kategorie</label>
                  <select
                      id="category-Select"
                      class="form-control m-auto"
                      name="categorySelect"
                      v-model="post.category">
                    >
                  </select>
                </div>
                <div class="col-12 mb-4">
                  <label class="form-label">Verwendungszweck</label>
                  <select
                      id="post-usage"
                      class="form-control"
                      name="usage"
                      v-model="post.usage">
                    >
                  </select>
                </div>
                <div class="form-label mb-2">Social-Media-Plattform (min. 1)</div>
                <div class="gap-2 d-flex flex-wrap" id="social-platform">
                  <button type="button" style="border:1px" :style="instagramStyle" class="badge badge-lg"
                          @click="this.post.instagram = !this.post.instagram">
                    <i class="fa fa-instagram"></i>
                  </button>
                  <button type="button" style="border:1px" :style="facebookStyle" class="badge badge-lg"
                          @click="this.post.facebook = !this.post.facebook">
                    <i class="fa fa-facebook"></i>
                  </button>
                  <button type="button" style="border:1px" :style="linkedinStyle" class="badge badge-lg"
                          @click="this.post.linkedin = !this.post.linkedin">
                    <i class="fa fa-linkedin"></i>
                  </button>
                  <p v-if="socialMediaErrorMessage" class="text-danger">Bitte wählen Sie mindestens
                    Social-Media-Platform</p>
                </div>
              </div>
              <div class="col-12 mb-3">
                <div class="mb-3" v-if="post.instagram  && post.instagramText !== '' && post.instagramText !== 'true'">
                  <div class="d-flex justify-content-between">
                    <label for="instaText" class="form-label">Instagram</label>
                    <button @click="copy('instaText')" class="border-0" style="background-color:white"><i
                        class="fa fa-clipboard"></i></button>
                  </div>
                  <textarea v-model="post.instagramText" class="form-control" id="instaText" rows="5"
                            maxlength="3000"></textarea>
                </div>
                <div class="mb-3" v-if="post.facebook  && post.facebookText !== '' && post.facebookText !== 'true'">
                  <div class="d-flex justify-content-between">
                    <label for="facebookText" class="form-label">Facebook</label>
                    <button @click="copy('facebookText')" class="border-0" style="background-color:white"><i
                        class="fa fa-clipboard"></i></button>
                  </div>
                  <textarea v-model="post.facebookText" class="form-control" id="facebookText" rows="5"
                            maxlength="3000"></textarea>
                </div>
                <div class="mb-3" v-if="post.linkedin && post.linkedinText !== '' && post.linkedinText !== 'true'">
                  <div class="d-flex justify-content-between">
                    <label for="linkedinText" class="form-label">LinkedIn</label>
                    <button @click="copy('linkedinText')" class="border-0" style="background-color:white"><i
                        class="fa fa-clipboard"></i></button>
                  </div>
                  <textarea v-model="post.linkedinText" class="form-control" id="linkedinText" rows="5"
                            maxlength="3000"></textarea>
                </div>
                <div class="mb-3" v-else-if="noSocialMediaTexts">
                  <p class="form-label">Text mit Chat KI für alle Platformen generieren lasen</p>
                </div>

                <button
                    @click="generatechatGPTText"
                    :disabled="chatGPTApiStateLoading"
                    type="button"
                    class="mt-2 mb-0 btn bg-info-soft"
                    id="kiFormInput"
                >
                  Generieren
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
            class="col-lg-4 d-flex d-md-none align-items-center justify-content-end gap-3 mt-3"
        >
          <button
              @click="deletePost"
              type="button"
              class="mt-2 mb-0 btn bg-dark"
          >
            Löschen
          </button>
          <button
              @click="savePost"
              type="button"
              class="mt-2 mb-0 btn bg-primary"
          >
            Speichern
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import api, {apiGet} from "@/api/api";
import SoftInput from "@/components/SoftInput.vue";
import moment from "moment";

const activeCompanyId = localStorage.getItem('companyId');


export default {
  name: "SinglePostView",
  components: {SoftInput},
  data() {
    return {
      socialMediaErrorMessage: false,
      post: {
        text: '',
        hashtags: '',
        instagramText: '',
        facebookText: '',
        linkedinText: '',
        time: '',
        date: '',
        images: '',
        choicesInstants: null,
        additionalInfoText: '',
        status: 'OPEN',
        declinedReason: null,
        usage: 'Beitrag',
        instagram: false,
        facebook: false,
        linkedin: false,
        category: '',
      },
      chatGPTApiStateLoading: false,
    }
  },
  computed: {
    instagramStyle() {
      return this.post.instagram ? 'color: white; background-color: #e91e63' : 'color: grey'
    },
    facebookStyle() {
      return this.post.facebook ? 'color: white; background-color: #1a237e' : 'color: grey'
    },
    linkedinStyle() {
      return this.post.linkedin ? 'color: white; background-color: #2196f3' : 'color: grey'
    },
    validateSocialMedia() {
      return this.post.instagram || this.post.facebook || this.post.linkedin
    },
    noSocialMediaTexts() {
      return this.post.instagramText === '' || this.post.instagramText === 'true' && this.post.facebookText === '' || this.post.facebookText === 'true' && this.post.linkedinText === '' || this.post.linkedinText === 'true'
    },

  },
  methods: {
    statusConverterName(item) {
      const nbr = item.text !== '' ? 0 : item.text !== '' ? 1 : item.text === '' ? 1 : 2
      return nbr === 0 ? 'Vollständig' : nbr === 1 ? 'Unvollständig' : nbr === 2 ? 'Fertig' : 'Fehler'
    },
    statusConverterColor(item) {
      const nbr = item.text !== '' ? 0 : item.text !== '' ? 1 : item.text === '' ? 1 : 2
      return nbr === 0 ? 'success' : nbr === 1 ? 'warning' : 'dark'
    },
    copy(id) {
      var copyText = document.getElementById(id);
      copyText.select();
      navigator.clipboard.writeText(copyText.value);
    },
    async savePost(moveBackToGallery = true) {
      this.socialMediaErrorMessage = false
      if (this.validateSocialMedia) {
        if (new Date(this.post.date + ' ' + this.post.time) !== null) {

          await api.put('post/save?companyId=' + this.$store.state.activeId.toString(), {
            text: encodeURI(this.post.text.trim()),
            hashtags: encodeURI(this.post.hashtags.trim()),
            status: this.post.status,
            usage: this.post.usage,
            category: this.post.category,
            publicationDate: new Date(this.post.date + ' ' + this.post.time),
            additionalInfo: encodeURI(this.post.additionalInfoText.trim()),
            instagramText: this.post.instagram && this.post.instagramText === 'true' || this.post.instagram && this.post.instagramText === '' ? 'true' : this.post.instagram && this.post.instagramText !== '' && this.post.instagram && this.post.instagramText !== 'true' ? encodeURI(this.post.instagramText.trim()) : '',
            facebookText: this.post.facebook && this.post.facebookText === 'true' || this.post.facebook && this.post.facebookText === '' ? 'true' : this.post.facebook && this.post.facebookText !== '' && this.post.facebook && this.post.facebookText !== 'true' ? encodeURI(this.post.facebookText.trim()) : '',
            linkedinText: this.post.linkedin && this.post.linkedinText === 'true' || this.post.linkedin && this.post.linkedinText === '' ? 'true' : this.post.linkedin && this.post.linkedinText !== '' && this.post.linkedin && this.post.linkedinText !== 'true' ? encodeURI(this.post.linkedinText.trim()) : '',
            id: this.post.id
          }).then(() => {
            if (moveBackToGallery) {
              this.$router.go(-1);
            }
          })
        } else {
          // todo: notify, that date is invalid
        }
      } else {
        const scrollToElementY = document.getElementById('social-platform').getBoundingClientRect().top
        window.scrollTo(0, scrollToElementY);
        this.socialMediaErrorMessage = true;
      }
    },
    async generatechatGPTText() {
      this.chatGPTApiStateLoading = true
      if (this.post.instagram && this.post.instagramText === 'true' || this.post.instagram && this.post.instagramText === '') {
        await api.post('chat/create', {
          text: encodeURI(this.post.text.trim()),
          hashtags: encodeURI(this.post.hashtags.trim()),
          socialPlatform: 'instagram',
        }).then((resp) => {
          if (resp.data.choices[0].message.content) {
            this.post.instagramText = resp.data.choices[0].message.content;
          }
        }).finally(() => {
          this.savePost(false);
        })
      }
      if (this.post.facebook && this.post.facebookText === 'true' || this.post.facebook && this.post.facebookText === '') {
        await api.post('chat/create', {
          text: encodeURI(this.post.text.trim()),
          hashtags: encodeURI(this.post.hashtags.trim()),
          socialPlatform: 'facebook',
        }).then((resp) => {
          if (resp.data.choices[0].message.content) {
            this.post.facebookText = resp.data.choices[0].message.content;
          }
        }).finally(() => {
          this.savePost(false);
        })
      }
      if (this.post.linkedin && this.post.linkedinText === 'true' || this.post.linkedin && this.post.linkedinText === '') {
        await api.post('chat/create', {
          text: encodeURI(this.post.text.trim()),
          hashtags: encodeURI(this.post.hashtags.trim()),
          socialPlatform: 'linkedin',
        }).then((resp) => {
          if (resp.data.choices[0].message.content) {
            this.post.linkedinText = resp.data.choices[0].message.content;
          }
        }).finally(() => {
          this.savePost(false);
        })
      }
      this.chatGPTApiStateLoading = false
    },
    goBackToGallery() {
      this.$router.push({path: '/post-gallery'})
    },
    async deletePost() {
      await api.post('post/delete', [this.$route.query.postId]).then(() => {
        this.$router.push({name: 'PostGallery', query: {c: this.$route.query.c}})
      })
    }
  },
  async mounted() {
    this.$store.state.showNavbarMini = false;
    const resp = await apiGet(localStorage.getItem('adminId'),'post/edit?id=' + this.$route.query.postId)
    this.post = resp.data
    this.post.text = decodeURI(resp.data.text)
    this.post.hashtags = decodeURI(resp.data.hashtags)
    this.post.date = decodeURI(moment(resp.data.publicationDate).format('yyyy-MM-DD'))
    this.post.time = decodeURI(moment(resp.data.publicationDate).format('HH:mm'))
    this.post.images = resp.data.images
    this.post.status = resp.data.status
    this.post.declinedReason = resp.data.declinedReason
    this.post.category = resp.data.category
    this.post.usage = decodeURI(resp.data.usage)
    this.post.additionalInfoText = decodeURI(resp.data.additionalInfo)
    this.post.id = this.$route.query.postId
    this.post.instagram = resp.data.instagramText !== ''
    this.post.facebook = resp.data.facebookText !== ''
    this.post.linkedin = resp.data.linkedinText !== ''
    this.post.instagramText = decodeURI(resp.data.instagramText)
    this.post.facebookText = decodeURI(resp.data.facebookText)
    this.post.linkedinText = decodeURI(resp.data.linkedinText)

    if (this.post.additionalInfoText === 'null') this.post.additionalInfoText = ''

    if (document.getElementById("image-status")) {
      const status = document.getElementById("image-status");
      this.choicesInstants = new Choices(status, {
        itemSelectText: '',
        searchEnabled: false,
      })

      this.choicesInstants.setChoices([
            {id: 'OPEN', name: 'Offen', selected: this.post.status === 'OPEN'},
            {id: 'IN_POGRESS', name: 'In Bearbeitung', selected: this.post.status === 'IN_POGRESS'},
            {id: 'DONE', name: 'Fertig', selected: this.post.status === 'DONE'},
            {id: 'IN_EXAMINATION', name: 'In Prüfung', selected: this.post.status === 'IN_EXAMINATION'},
            {id: 'APPROVED', name: 'Freigegeben', selected: this.post.status === 'APPROVED'},
            {id: 'DECLINED', name: 'Abgelehnt', selected: this.post.status === 'DECLINED'}],
          'name', 'name', false)
      const postId = this.post.id
      this.choicesInstants.passedElement.element.addEventListener(
          'change',
          async function (event) {
            await api.put('post/status', {
              postId: postId,
              status: event.target.value
            })
          },
          false,
      );
    }

    //Category select field

    if (document.getElementById("category-Select")) {
      const status = document.getElementById("category-Select");
      this.choicesInstants = new Choices(status, {
        itemSelectText: '',
        searchEnabled: false,
      })

      const resp = await apiGet(localStorage.getItem('adminId'),'/company/categories?companyId=' + this.$store.state.activeId)
      const categoryOptions = []
      let category = null

      for (let i = 0; i < resp.data.length; i++) {
        category = {
          id: resp.data[i].id,
          name: resp.data[i].category,
          selected: this.post.category?.id === resp.data[i].id
        }
        categoryOptions.push(category);
      }

      this.choicesInstants.setChoices(categoryOptions,
          'name', 'name', false)
    }

    if (document.getElementById("post-usage")) {
      const usage = document.getElementById("post-usage");
      this.choicesInstants = new Choices(usage, {
        searchEnabled: false,
      })

      this.choicesInstants.setChoices([
            {id: 'Entry', name: 'Beitrag', selected: this.post.usage === 'Beitrag'},
            {id: 'Campagne', name: 'Kampagne', selected: this.post.usage === 'Kampagne'}],
          'name', 'name', false)
    }
  },
};
</script>
<style>

.choices[data-type*=select-one] > .choices__list > .choices__list > .choices__item--selectable {
  padding-right: 0;
}

.choices[data-type*=select-one] > .choices__list > .choices__list > .choices__item--selectable::after {
  display: none;
}
</style>