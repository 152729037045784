<template>
  <div v-if="loading" class="card" style="max-height: 150px;">
    <Skeleton class="skeleton-custom" height="100%"></Skeleton>
  </div>
  <div v-else>
    <div class="card border my-3 ">
      <div class="card-body">
        <div class="card-section d-flex justify-content-center">
          <h3>{{ title }}</h3>
        </div>
        <p class="d-flex justify-content-center">{{ publicationDate }}</p>
        <div class="card-section col-12 d-flex justify-content-center">
          <img class="border-radius-lg z-index-2"
               style="max-height: 10rem; object-fit: cover; max-width: 100%"
               :src="image" alt="postImg">
        </div>
        <div class="card-section col-12 pt-3">
          <p class="text-sm">{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "ReviewCard",
  components: {
    SoftButton,
    Skeleton,
  },
  props: {
    postId: {
      type: Number,
      default: 0
    },
    image: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    publicationDate: {
      type: Date,
      default: new Date(),
    },
    action: {
      type: Object,
      route: String,
      color: String,
      label: String,
      default: () => {
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    skeletonPosts: {
      type: Number,
      default: 4,
    }
  },
};
</script>
<style>
.skeleton-custom {
  background-color: #f0f0f0;
  overflow: hidden;
  position: relative;
  border-radius: 1rem;
}

.skeleton-custom::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.4), transparent);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>