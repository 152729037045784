import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import easySocialLogin from "@/views/easy-social/Login.vue";
import Contact from "@/views/easy-social/Contact.vue";
import PostGallery from "@/views/easy-social/PostGallery.vue";
import SinglePostView from "@/views/easy-social/SinglePostView.vue";
import Uploader from "@/views/easy-social/Uploader.vue";
import LandingPage from "@/views/easy-social/LandingPage.vue";
import Kanban from "@/views/easy-social/Kanban.vue";
import Calendar from "@/views/easy-social/Calendar.vue";
import ReviewPage from "@/views/easy-social/ReviewPage.vue";


const routes = [
  {
    path: "/landing-page",
    name: "LandingPage",
    component: LandingPage,
    meta: {
      auth: true,
      roles: ['admin', 'medien', 'customer']
    }
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      auth: false,
      roles: ['admin', 'medien', 'customer']
    }
  },
  {
    path: "/uploader",
    name: "Uploader",
    component: Uploader,
    meta: {
      auth: true,
      roles: ['admin', 'medien', 'customer']
    }
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar,
    meta: {
      auth: true,
      roles: ['admin', 'medien']
    }
  },
  {
    path: "/single-post",
    name: "Single Post View",
    component: SinglePostView,
    meta: {
      auth: true,
      roles: ['admin', 'medien']
    }
  },
  {
    path: "/post-gallery",
    name: "PostGallery",
    component: PostGallery,
    meta: {
      auth: true,
      roles: ['admin', 'medien']
    }
  },
  {
    path: "/kanban",
    name: "Kanban",
    component: Kanban,
    meta: {
      auth: true,
      roles: ['admin', 'medien']
    }
  },
  {
    path: "/login",
    name: "easy social login",
    component: easySocialLogin,
    meta: {
      auth: false,
      roles: ['admin', 'medien', 'customer']
    }
  },
  {
    path: "/post-review",
    name: "ReviewPage",
    component: ReviewPage,
    meta: {
      auth: true,
      roles: ['admin', 'medien', 'customer']
    }
  },
  {
    path: '/:catchAll(.*)',
    component: easySocialLogin,
    name: 'NotFound'
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const userRole = to.name  === "post-review" || localStorage.userRole === "customer" ?  'customer' : 'admin'
  const loggedIn = true

  //CHECK security levels
  // 1.role needed
  // 2.login only
  // 3.public page
  if(to.meta.auth === undefined){
    next({ name: 'easy social login' })
  }
  if (to.meta.roles.length > 0) {
    // role is needed
    if (to.meta.roles.includes(userRole)) {
      next()
    } else {
      next({ name: 'easy social login' })
    }
  } else if (to.meta.auth) {
    // login needed
    if (loggedIn) {
      next()
    } else {
      next({name: 'easy social login'})
    }
  } else {
    next()
  }
})

export default router;
