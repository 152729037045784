<template>
  <a
    class="nav-link"
    v-bind="$attrs"
    type="button"
  >
    <div
      :class="isRTL ? ' ms-2' : 'me-2'"
    >
      <slot name="logo"></slot>
    </div>
    <span
      class="nav-link-text"
      :class="isRTL ? ' me-1' : 'ms-1'"
      >{{ navText }}</span
    >
  </a>
  <div class="ms-3">
    <slot name="list"></slot>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "SidenavCollapse",
  props: {
    navText: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
};
</script>
