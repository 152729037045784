<template>
  <div class="py-4 container-fluid ">
    <div class="card m-4 mb-4 p-3">
      <div class="row d-flex justify-content-between align-items-center">
        <div class="col-6"> Wähle bitte ein Unternehmen aus.</div>
        <div class="col-6">
          <soft-input
              v-model="searchInput"
              placeholder="Suchen"
              icon="fa fa-search"
              iconDir="right"
              class="m-0"
          />
        </div>
      </div>
    </div>
    <div class="card m-4">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              <div>Unternehmen</div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="p-3" v-if="searchInput !== '' && searchList.length === 0">
              Zu der Suchanfrage gab es kein Ergebnis.
            </td>
          </tr>
          <tr v-for="(company, idx) in searchList" :key="idx">
            <td class="p-3">
              <div class="d-flex justify-content-between">
                <router-link :to="{name: 'PostGallery'}">
                  <soft-button
                      id="companyBtn"
                      variant="outline"
                      color="none"
                      class="shadow-none p-0 no-uppercase"
                      @click="setActiveCompany(company.id)"
                  >
                    <div class="d-flex px-2 py-1">
                      <div v-if="company.base64Image !== null">
                        <img :src="company.base64Image"
                             class="avatar avatar-sm me-4"
                             :alt="company.name"
                        >
                      </div>
                      <div v-else>
                        <i style="margin-top: 0.4rem; margin-right: 1rem" class="fa fa-solid fa-user"></i>
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 "> {{ company.name }}</h6>
                      </div>
                    </div>
                    <div class="d-flex align-content-start success-hide" style="color:limegreen" :id="company.name + '-success'">Benutzer erfolgreich erstellt</div>
                  </soft-button>
                </router-link>
                <button :id="'btn' + company.name" type="button" class="btn btn-primary mb-0" data-bs-toggle="modal" @click="hideSideNav"
                        :data-bs-target="'#exampleModal' + idx">
                  <i class="fa fa-solid fa-plus"></i>
                </button>
              </div>
              <div class="modal fade pe-4" :id="'exampleModal' + idx" tabindex="-1"
                   :aria-labelledby="'exampleModalLabel' + idx"
                   aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header d-flex row" style="margin-left: 5px; margin-right:5px">
                      <h1 class="modal-title fs-5 text-wrap" :id="'exampleModalLabel' + idx">Neuen Benutzer für
                        '{{ company.name }}' anlegen</h1>
                      <div style="color:red">{{ errorMessage }}</div>
                    </div>
                    <div class="modal-body">
                      <label>Name des Benutzers</label>
                      <soft-input placeholder="" v-model="userName" :id="'name' + company.name"></soft-input>
                      <label>Passwort des Benutzers</label>
                      <soft-input placeholder="" v-model="password" type="password"
                                  :id="'password' + company.name"></soft-input>
                      <label>Wiederholung des Passwortes</label>
                      <soft-input placeholder="" v-model="passwordRepeat" type="password"
                                  :id="'password2' + company.name"></soft-input>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn" :id="'closebtn' + idx" style="color:white; background-color: #333"
                              data-bs-dismiss="modal" @click="clear()">Abbrechen
                      </button>
                      <button type="button" class="btn btn-primary" @click="newUser(company, idx)">Erstellen</button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Button trigger modal -->
            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
    <div class="card m-4 p-3 d-flex align">
      <p>Neues Unternehmen erstellen</p>
      <div class="flex-row d-flex">
        <soft-input id="companyName" type="text" placeholder="Name des Unternehmens..." v-model="companyName"
                    style="width: 50rem; margin-right: 20px"></soft-input>
        <soft-button style="width: 10rem; height: 40px" color="primary" @click="createNewCompany">Erstellen
        </soft-button>
      </div>
      <!-- Fehlermeldung -->
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {api, apiGet} from "@/api/api";
import SoftButton from "@/components/SoftButton.vue";
import SoftInput from "@/components/SoftInput.vue";
import {co} from "@fullcalendar/core/internal-common";


export default {
  name: "LandingPage",
  components: {SoftInput, SoftButton},

  data() {
    return {
      companies: [],
      searchInput: '',
      visible: false,
      companyName: '',
      userName: '',
      password: '',
      passwordRepeat: '',
      errorMessage: '',
    }
  },

  methods: {
    co,
    ...mapState(["showNavbarMini"]),
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    setActiveCompany(companyId) {
      this.$store.dispatch('setActiveCompany', companyId);
      localStorage.setItem('companyId', companyId)
    },
// Hier sind die Änderungen zur Fehlermeldung bei Vorhandener Company
    async createNewCompany() {
      // Prüfen, ob der Firmenname vorhanden ist
      if (!this.companyName.trim()) {
        this.errorMessage = 'Bitte gib einen Namen für das Unternehmen ein.';
        return;
      }

      // Überprüfen, ob der Name bereits existiert
      const companyExists = this.companies.some(company =>
          company.name.toLowerCase() === this.companyName.toLowerCase()
      );

      if (companyExists) {
        this.errorMessage = 'Ein Unternehmen mit diesem Namen existiert bereits.';
        return;
      }

      try {
        // API-Anfrage zum Erstellen der neuen Firma
        await api.post('company/initSave', { name: this.companyName });

        // Nach erfolgreicher Erstellung zur Liste hinzufügen
        this.companies.push({
          name: this.companyName,
          base64Image: null
        });

        // Eingabefeld zurücksetzen
        this.companyName = '';
        this.errorMessage = ''; // Fehlernachricht zurücksetzen
      } catch (error) {
        console.error('Fehler beim Erstellen des Unternehmens:', error);
        this.errorMessage = 'Ein Fehler ist beim Erstellen des Unternehmens aufgetreten. Bitte versuche es später erneut.';
      }
    },

    clear() {
      this.userName = ''
      this.password = ''
      this.passwordRepeat = ''
      this.errorMessage = ''
    },
    // Hier sind die Änderungen zur Fehlermeldung bei existierendem User
    async newUser(company, idx) {
      try {
        let resp = await api.post('/auth/exists', {
          username: this.userName,
          company: company
        });

        if (!this.userName || !this.password || !this.passwordRepeat) {
          this.errorMessage = 'Bitte fülle alle Felder aus';
        } else if (this.password !== this.passwordRepeat) {
          this.errorMessage = 'Das Passwort stimmt nicht überein';
        } else if (this.password.length < 6 || this.password.length > 40) {
          this.errorMessage = 'Das Passwort muss zwischen 6 und 40 Zeichen lang sein';
        } else if (this.userName.length < 3 || this.userName.length > 20) {
          this.errorMessage = 'Der Name muss zwischen 3 und 20 Zeichen lang sein';
        } else if (resp.data) {
          this.errorMessage = 'Es gibt bereits einen Benutzer mit diesem Namen';
        } else {
          this.errorMessage = '';
          await api.post('/auth/signup', {
            username: this.userName,
            password: this.password
          });
          await api.post('/auth/createUserCompany', {
            username: this.userName,
            company: company
          });
          document.getElementById('closebtn' + idx).click();
          this.successMessage(company);
        }
      } catch (error) {
        console.error('Fehler bei der Benutzererstellung:', error);
        this.errorMessage = 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.';
      }
    },

    successMessage(company){
      const successText = document.getElementById(company.name + '-success')
      successText.classList.remove('success-hide')
      setTimeout( () => {
        successText.classList.add('success-hide')
      }, 5000)
    },
    hideSideNav(){
      const sidenav_show = document.querySelector(".g-sidenav-show");
      sidenav_show.classList.add("g-sidenav-hidden");
      sidenav_show.classList.remove("g-sidenav-pinned");
    }
  },

  computed: {
    searchList() {
      return this.searchInput === '' ? this.companies : this.companies.filter((company) => (company.name?.toLowerCase().match(this.searchInput.toLowerCase())))
    },
  },

  async mounted() {
    localStorage.setItem('userRole', 'admin')

    this.$store.state.showNavbarMini = false;
    this.hideSideNav()
    const sidenav_show = document.querySelector(".g-sidenav-show");
    sidenav_show.children[0].style.setProperty("background-color", "white", "important");

    await apiGet(localStorage.getItem('adminId'), '/company/all').then((resp) => {
      localStorage.setItem('companies', JSON.stringify(resp.data))
      this.companies = resp.data
    }).catch((e) => {
      console.error(e)
      this.$router.push({name: 'Login'})
    })

    // await api.get('/company/all')
  }
}
</script>
<style scoped>
.no-uppercase {
  text-transform: unset !important;
}
.success-hide{
  display: none !important;
}
.error-message {
  color: red;
  margin-top: 10px;
}
</style>