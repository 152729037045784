<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="mb-4 col">
        <div
            class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
        >
          <p class="text-center text-muted">
            <span id="copyright-span">©{{ new Date().getFullYear() }}</span>
            <a
                id="linkToCopyrightOwner"
                href="https://www.software-medien.de"
                class="font-weight-bold"
                target="_blank"
            > Lüttel Software & Medien GmbH</a>
          </p>

        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
