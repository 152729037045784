<template>
  <div class="card mb-5 pb-3">
    <div class="card-body">
      <div class="card-section row justify-content-center">
        <review-card v-if="item.instagramText"
                     title="Instagram"
                     :skeleton-posts=2
                     :post-id="item.id"
                     :text="item.instagramText"
                     :image="item.images[0].base64Image"
                     :publication-date="item.formatedDate"
                     class="col-12 col-sm"
        />
        <review-card v-if="item.facebookText"
                     title="Facebook"
                     :skeleton-posts=2
                     :post-id="item.id"
                     :text="item.facebookText"
                     :image="item.images[0].base64Image"
                     :publication-date="item.formatedDate"
                     class=" col-12 col-sm"
        />
        <review-card v-if="item.linkedinText"
                     title="LinkedIn"
                     :skeleton-posts=2
                     :post-id="item.id"
                     :text="item.linkedinText"
                     :image="item.images[0].base64Image"
                     :publication-date="item.formatedDate"
                     class=" col-12 col-sm"
        />
      </div>
      <div class="col-12 justify-content-center">
        <soft-button
            class="my-4 mb-2"
            color="primary"
            full-width
            type="submit"
            @click="approvePost()"
        >Freigeben
        </soft-button>
        <div v-if="declined" class="mt-3">
          <p id="issueText">* Bitte gebe einen Grund an:</p>
          <textarea v-model="declinedReason"
                    class="form-control"
                    rows="2"
                    maxlength="5000"
          >
            </textarea>
        </div>
        <soft-button
            class="my-4 mb-2"
            color="secondary"
            full-width
            type="cancel"
            @click="declinePost()"
        >Ablehnen
        </soft-button>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import api from "@/api/api";
import ReviewCard from "@/components/ReviewCard.vue";


export default {
  name: "ReviewPlattforms",
  components: {
    ReviewCard,
    SoftButton,
  },
  data() {
    return {
      removedList: [],
      declined: false,
      declinedReason: ""
    }
  },
  props: {
    item: {
      type: Object,
      default: [],
    },
  },

  methods: {
    async approvePost() {
      await api.put('post/status', {
        postId: this.item.id,
        status: 'Freigegeben',
      });
      this.item.status = 'APPROVED'
    },
    async declinePost() {
      if (!this.declined) {
        this.declined = true;
      } else if (this.declinedReason !== "") {
        await api.put('post/status', {
          postId: this.item.id,
          status: 'Abgelehnt',
          declinedReason: this.declinedReason
        });
        this.item.status = 'DECLINED'

      } else {
        let issueText = document.getElementById("issueText");
        issueText.style.color = "red";
      }
    },
  },

};
</script>
